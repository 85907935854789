import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Recommendation from '../components/recommendation';
import SignupForm from '../components/signupform'

const Gear = ({ pageContext, data }) => {
  const { edges, totalCount } = data.allMarkdownRemark;
  return (
    <Layout>
        <SEO 
          title="Snoffeecob Gear Reviews"
          description="Snoffeecob Gear Reviews"
        />
        <div className="signup-box">
          <SignupForm />
        </div>
            <h1>Snoffeecob Gear Reviews</h1>
        {edges.map(({ node }) => {
          return (
            <article className="post" key={node.fields.slug}>
              {node.frontmatter.img &&
                node.frontmatter.img.childImageSharp &&
                node.frontmatter.img.childImageSharp.fluid && (
                  <Link
                    to={node.fields.slug}
                    className="post-thumbnail"
                    style={{
                      backgroundImage: `url(${node.frontmatter.img.childImageSharp.fluid.src})`,
                    }}
                  />
                )}
              <div className="post-content">
                <h2 className="post-title">
                  <Link to={node.fields.slug}>{node.frontmatter.title}</Link>
                </h2>
                {node.frontmatter.recommendation && (
                  <Recommendation
                    recommendation={node.frontmatter.recommendation}
                  />
                )}

                <p>{node.excerpt}</p>

                { node.frontmatter.price && node.frontmatter.store &&
                <p>
                    <span>€{(node.frontmatter.price/100).toFixed(2)}</span>
                    <span>, from <a href={ node.frontmatter.url }>{node.frontmatter.store}</a>. </span>
                </p>
                }
                <span className="post-date">
                  {node.frontmatter.date}&nbsp;&nbsp;—&nbsp;
                </span>
                <span className="post-words">
                  {node.timeToRead} minute read
                </span>
              </div>
            </article>
          )
        })}
        <div className='signup-box'>
            <SignupForm/>
        </div>
    </Layout>
  );
};

export default Gear

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: ["gear"] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          excerpt
          timeToRead
          frontmatter {
            title
            price
            store
            date(formatString: "MMMM DD, YYYY")
            img {
              childImageSharp {
                fluid(maxWidth: 400) {
                  aspectRatio
                  base64
                  sizes
                  src
                  srcSet
                }
              }
            }
          }
        }
      }
    }
  }
`;
