import React from 'react'
import Img from "gatsby-image"

const Recommendation = ({ recommendation }) => (
    <p>
        <span className="recommendation"><img src="/corn-cob-white.png" />cobtastic</span>
    </p>
)

export default Recommendation
